import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import assignmentApi from "../../apis/assignmentApi";
import firmApi from "../../apis/firmApi";
import { FIRM_TYPE } from "../../utilities/constants";
import securityApi from "../../apis/securityApi";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [auditingFirms, setAuditingFirms] = useState([]);
  const [auditedFirms, setAuditedFirms] = useState([]);
  const [users, setUsers] = useState([]);
  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            "Authorization": idToken
          },
        };

        const [responseAssignments, responseFirms, responseUsers] =
        await Promise.all([
          assignmentApi.get(`/assignments/assignto/${loggedInUser.email}`,requestHeader),
          firmApi.get(`/firms`, requestHeader),
          securityApi.get(`/users`, requestHeader)
        ]);

        setAssignments(responseAssignments.data.body);
        setAuditingFirms(responseFirms.data.body.filter(firm => firm.firm_type_id === FIRM_TYPE.AUDITING_FIRM));
        setAuditedFirms(responseFirms.data.body.filter(firm => firm.firm_type_id === FIRM_TYPE.FIRM_TO_BE_AUDITED));
        setUsers(responseUsers.data.body);
        setIsLoading(false);
      } catch (err) {
        // Handle Error Here
        console.error(err);
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [loggedInUser.email, tenantId]);

  return (
    <Box m="20px">
      {/* HEADER
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ieQip" subtitle="Welcome To Your ieQip Dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box> */}

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? '...' : assignments.length}
            subtitle="Assignments"
            // progress="0.80"
            // increase="+99%"
            icon={
              <ReceiptOutlinedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? '...' : auditedFirms.length}
            subtitle="Firms Audited"
            // progress="0.50"
            // increase="+99%"
            icon={
              <ContactsOutlinedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? '...' : auditingFirms.length}
            subtitle="Auditing Firms"
            // progress="0.30"
            // increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? '...' : users.length}
            subtitle="Users"
            // progress="0.75"
            // increase="+99%"
            icon={
              <PeopleOutlinedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>


        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Inspections
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.blueAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.blueAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box> */}

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.blueAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
