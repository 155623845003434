import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// mui import
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

// project import
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { getLocalDate } from "../../utilities/helpers";
import { setInspection, setLookup } from "../../state";

import { LOOKUP_CATEGORIES, LOOKUP_CFR_TYPES } from "../../utilities/constants";
import assignmentApi from "../../apis/assignmentApi";
import lookupApi from "../../apis/lookupApi";


const Assignments = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const lookup = useSelector((state) => {
    return state.lookup;
  });

  const columns = [
    {
      field: "subject",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "firm_name",
      headerName: "Auditing Firm",
      flex: 1,
    },
    {
      field: "firm_to_be_audited_name",
      headerName: "Firm To Be Audited",
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
    },
    {
      field: "assignment_status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "target_complete_date",
      headerName: "Target Date",
      flex: 1,
    },
    // {
    //   field: "operation_type_id",
    //   headerName: "Operation Type",
    //   flex: 1,
    // },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Inspect",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Tooltip title="Inspection">
              <IconButton color="warning" onClick={() => handleInspection(row)} variant="">
                  <PersonSearchIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "assignment_uid",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Tooltip title="Edit">
              <IconButton
                color="success"
                aria-label="Edit"
                component={Link}
                to={`/assignments/${row.assignment_uid}/edit`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={() => handleDelete(row)} variant="">
                  <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            "Authorization": idToken
          },
        };

        // show assignments are assgined to or created by logined user
        const responseAssignments = await assignmentApi.get(
          `/assignments/assignto/${loggedInUser.email}`,
          requestHeader
        );

        if (!lookup) {
          const [responseGeneral, responsePaiDrug, responsePaiApi, 
            responseSection, responseSubsection, responseRegulation, responseCitationLanguage, 
            // Q7
            responseSectionQ7, responseSubsectionQ7, responseRegulationQ7, responseCitationLanguageQ7,
            // Cfr111
            responseSectionCfr111, responseSubsectionCfr111, responseRegulationCfr111, responseCitationLanguageCfr111,
            // Cfr820
            responseSectionCfr820, responseSubsectionCfr820, responseRegulationCfr820, responseCitationLanguageCfr820,
            // Cfr820211
            responseSectionCfr820211, responseSubsectionCfr820211, responseRegulationCfr820211, responseCitationLanguageCfr820211,
            // Cfr211820
            responseSectionCfr211820, responseSubsectionCfr211820, responseRegulationCfr211820, responseCitationLanguageCfr211820] 
            = await Promise.all([
            lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.GENERAL}`, requestHeader), // get all generals
            lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.PAI_DRUG}`, requestHeader), // get all pai drug
            lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.PAI_API}`, requestHeader), // get all pai api
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR211`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR211`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR211`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR211`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/Q7API`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/Q7API`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/Q7API`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/Q7API`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR111`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR111`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR111`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR111`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR820`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR820`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR820`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR820`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR820211`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR820211`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR820211`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR820211`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR211820`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR211820`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR211820`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR211820`, requestHeader), // get all citation languages
          ]);
    
          dispatch(
            setLookup({ lookup: {
              generals: responseGeneral.data.body,
              paiDrugs: responsePaiDrug.data.body,
              paiApis: responsePaiApi.data.body,
              // cfr211
              sections: responseSection.data.body,
              subSections: responseSubsection.data.body,
              regulations: responseRegulation.data.body,
              citationLanguages: responseCitationLanguage.data.body,
              // Q7
              sectionsQ7: responseSectionQ7.data.body,
              subSectionsQ7: responseSubsectionQ7.data.body,
              regulationsQ7: responseRegulationQ7.data.body,
              citationLanguagesQ7: responseCitationLanguageQ7.data.body,
              // cfr111
              sectionsCfr111: responseSectionCfr111.data.body,
              subSectionsCfr111: responseSubsectionCfr111.data.body,
              regulationsCfr111: responseRegulationCfr111.data.body,
              citationLanguagesCfr111: responseCitationLanguageCfr111.data.body,
              // Cfr820
              sectionsCfr820: responseSectionCfr820.data.body,
              subSectionsCfr820: responseSubsectionCfr820.data.body,
              regulationsCfr820: responseRegulationCfr820.data.body,
              citationLanguagesCfr820: responseCitationLanguageCfr820.data.body,
              // Cfr820211
              sectionsCfr820211: responseSectionCfr820211.data.body,
              subSectionsCfr820211: responseSubsectionCfr820211.data.body,
              regulationsCfr820211: responseRegulationCfr820211.data.body,
              citationLanguagesCfr820211: responseCitationLanguageCfr820211.data.body,
      
              // Cfr211820
              sectionsCfr211820: responseSectionCfr211820.data.body,
              subSectionsCfr211820: responseSubsectionCfr211820.data.body,
              regulationsCfr211820: responseRegulationCfr211820.data.body,
              citationLanguagesCfr211820: responseCitationLanguageCfr211820.data.body
              }
            })
          );
        }

        // reformat date time
        const data = responseAssignments.data.body.map((item) => {
          item.target_complete_date = getLocalDate(item.target_complete_date);
          return item;
        });
        setAssignments(data);
        setIsLoading(false);
      } catch (err) {
        // Handle Error Here
        console.error(err);
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [loggedInUser.email, tenantId]);

  const handleDelete = async (assignment) => {
    try {
      setIsLoading(true);
      await assignmentApi.put('/assignments/disableAssignment', assignment);
      setAssignments(assignments.filter(item => item.assignment_uid !== assignment.assignment_uid));
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      console.error(err);
      setIsLoading(false);
    }
  }

  const handleInspection = async (assignment) => {
    try {
      dispatch(
        setInspection({
          isShowInspection: true,
          inspectionUid: assignment.inspection_uid,
          assignmentUid: assignment.assignment_uid
        })
      );
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  return (
    <Box m="20px">
      <Header title="ASSIGNMENTS" subtitle="Managing the Assignments" />
      <Box display="flex" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          component={Link}
          to="/assignments/create"
        >
          Create Assignment
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection={false} rows={assignments} columns={columns} loading={isLoading} />
      </Box>
    </Box>
  );
};

export default Assignments;
