import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Grid, Divider, Button, CircularProgress, Box, Backdrop, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { citationsHasNoSytemChoice, transformCitationLanguage } from "../../utilities/helpers";
import { COVERAGE_CHOICE_CITATION_GUID, COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID, REPORT_TYPE } from "../../utilities/constants";
import inspectionApi from "../../apis/inspectionApi";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
import assignmentApi from "../../apis/assignmentApi";
import { getLocalDate } from "../../utilities/helpers";
import ObservationSignatureDialog from "./ObservationSignatureDialog";
import IconButton from '@mui/material/IconButton';
import DrawIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ObservationSignatureAuditDialog from "./ObservationSignatureAuditDialog";
import {PDFDownloadLink} from '@react-pdf/renderer';
import ObservationReportPdf from "./ObservationReportPdf";
import assignmentAssignToApi from "../../apis/assignmentAssignToApi";

const RootStyle = styled("div")({
  flexGrow: 1,
  margin: "20px",
});

const PrintButton = styled(Button)({
  marginBottom: "0.5em",
});

const IObserved = styled(Typography)({
  fontWeight: 700,
});

const ObservationIndex = styled(Grid)({
  marginBottom: "1em",
});

const SystemChoice = styled(Typography)({
  marginTop: "1em",
  marginBottom: "1em",
});

const BoxText = styled(Box)({
  paddingTop: "0.5em",
  paddingLeft: "0.5em",
});

const ObservationReportGenerator = ({ reportType, setShowReportPreview }) => {
  const reportComponentRef = useRef();
  return (
    <RootStyle>
      <ObservationReportGeneratorPrintable ref={reportComponentRef} reportType={reportType} setShowReportPreview={setShowReportPreview} />
    </RootStyle>
  );
};

const ObservationReportGeneratorPrintable = React.forwardRef((props, ref) => {
  const [codeTables, setCodeTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionCitations, setQuestionCitations] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [assignmentsignatures, setAssignmentsignatures] = useState([]);
  const [allowedAssignTos, setAllowedAssignTos] = useState([]);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [openSignatureAuditDialog, setOpenSignatureAuditDialog] = useState(false);
  const [selectedAssignmentSignature, setSelectedAssignmentSignature] = useState(null);
  const [selectedAssignmentSignatureAudits, setSelectedAssignmentSignatureAudits] = useState([]);


  const { inspectionUid } = useParams();
  let globalObservationIndex = 0;
  let globalDiscussionIndex = 0;

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const reportType = props.reportType;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const requestHeader = {
        headers: {
          "x-eqip-tenantid": tenantId,
          "Authorization": idToken
        },
      };
      const [responseInspection, responseCodeTables, responseAssignment] = 
        await Promise.all([
          inspectionApi.get(`/inspections/${inspectionUid}`), 
          codeTablePostgresApi.get(`/codetables/codetabletypes/6,10`),
          assignmentApi.get(`/assignments/inspection/${inspectionUid}`, requestHeader)
        ]);

      const assignmentId = responseAssignment?.data.body[0].id;
      const responseAssignmentSignature = await assignmentApi.get(`/assignments/${assignmentId}/assignmentsignatures`);
      const responseAssignmentAssignTos = await assignmentAssignToApi.get(`/api/assignmentassigntos/assignmentid/${assignmentId}`, requestHeader);

      // Create seperate QuestionCitations array and use it to display
      const tempProtocolQualities = [];
      let citationIndex = 0;
      responseInspection?.data.body[0]?.protocol_quality?.forEach((protocolQuality) => {
        const detail = protocolQuality?.detail;
        if (protocolQuality?.detail?.coverageChoice === COVERAGE_CHOICE_CITATION_GUID) {
          if (!detail.index) {
            detail.index = citationIndex++;
          }
          tempProtocolQualities.push(protocolQuality);
        }
        return undefined;
      });

      tempProtocolQualities.sort((a, b) => a?.index - b?.index);

      if (responseInspection.data.body[0].system_choice_observation) {
        setCodeTables(responseInspection.data.body[0].system_choice_observation);
      } else {
        setCodeTables(responseCodeTables.data.body);
      }

      setQuestionCitations(responseInspection?.data.body[0]?.protocol_quality);
      setAssignment(responseAssignment?.data.body[0]);
      setAssignmentsignatures(responseAssignmentSignature?.data.body);
      setAllowedAssignTos(getAllowAssignedToUsers(responseAssignmentAssignTos?.data, responseCodeTables.data.body, responseAssignmentSignature?.data.body));
      setLoading(false);
    })();
  },
  // eslint-disable-next-line 
  [inspectionUid, tenantId, idToken]);

  const getAssignmentSignatureByAssignToEmail = (assignmentSignatures, assign_to_email) => {
     const  foundSssignmentsignatures = assignmentSignatures?.filter(signature => signature.assign_to_email === assign_to_email)
     if (foundSssignmentsignatures.length > 0) {
        return foundSssignmentsignatures[0];
     }
     return null
  }

  const getAllowAssignedToUsers = (assignmentAssignTos, codeTablesRole, assignmentSignatures) => {
    let allowedAssignToList = [];

    const accessMgmts = assignmentAssignTos.map(assignmentAssignTosItem => {
      const foundRole = codeTablesRole.filter((item) => 
        item.code_table_type_id === 10 && 
        item.id === assignmentAssignTosItem.role_id)[0];

      const foundAssignmentSignature = getAssignmentSignatureByAssignToEmail(assignmentSignatures, assignmentAssignTosItem.assign_to);

      return {
        user_name: assignmentAssignTosItem.assign_to,
        roleUid: foundRole.code_table_uid,
        roleName: foundRole.description,
        signature_url: foundAssignmentSignature?.signature_url,
        assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid,
      };
    });
    
    //2.Set allowToSign flag to true to current user, so it can be signed
    //If current user doesn't assign to Assigment.assign_to list, system will not show current user to sign the observation report
    const currentLoginUser = accessMgmts.filter((item) => item.user_name === loggedInUser.email)[0];
    if (currentLoginUser) {
      currentLoginUser.signature_url = getAssignmentSignatureByAssignToEmail(assignmentSignatures, currentLoginUser?.user_name)?.signature_url;
      currentLoginUser.allowToSign = true;
    }

    //3.Finallize the allowedAssignToList
    allowedAssignToList.push(...accessMgmts);

    return allowedAssignToList;
  };

  const handleOpenSignatureDialog = (assignmentSignature) => {
    setOpenSignatureDialog(true);
    setSelectedAssignmentSignature(assignmentSignature);
  }

  const handleAssignmentSignature = (assignmentSignature) => {
    setAllowedAssignTos(allowedAssignTos.map(item => {
      if (item.user_name === assignmentSignature.assign_to_email) {
        return {
          ...item,
          signature_url: assignmentSignature.signature_url
        }
      }
      return item;
    }));
  }

  const handleDeleteAssignmentSignature = async (assignmentSignature) => {
    setLoading(true);
    try {
      await assignmentApi.delete(
        `/assignments/${assignmentSignature.assignment_id}/assignmentsignatures/${assignmentSignature.assignment_signature_uid}`);
      setAllowedAssignTos(allowedAssignTos.map(item => {
        if (item.user_name === assignmentSignature.assign_to_email) {
          return {
            ...item,
            signature_url: null
          }
        }
        return item;
      }))
    } catch (error) {
      console.error("Error deleting signature:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleAssignmentSignatureAudit = async (assignmentSignature) => {
    setLoading(true);
    try {
      const requestHeader = {
        headers: {
          "x-eqip-tenantid": tenantId,
          "Authorization": idToken
        },
      };
      const response = await assignmentApi.get(
        `/assignments/${assignmentSignature.assignment_id}/assignmentsignatureaudits/${assignmentSignature.assign_to_email}`, requestHeader);
        
      setOpenSignatureAuditDialog(true)
      setSelectedAssignmentSignatureAudits(response?.data.body);
    } catch (error) {
      console.error("Error auditing signature:", error);
    } finally {
      setLoading(false);
    }
  }

  const foundCitationHasNoSystemChoice = citationsHasNoSytemChoice(questionCitations);

  return true ? (
    <RootStyle ref={ref}>
      <Stack direction="row" spacing={1}>
        <PDFDownloadLink document={<ObservationReportPdf fileName='Observation Report' 
                                      reportType={reportType} 
                                      assignment={assignment} 
                                      codeTables={codeTables}
                                      questionCitations={questionCitations}
                                      allowedAssignTos={allowedAssignTos}
                                      />}>
          {({loading}) => loading ? (
            <Button>Loading Document ...</Button>
          ) : (
            <PrintButton variant="contained" color="primary">Export to pdf</PrintButton>
          )}
        </PDFDownloadLink>
        <Button variant="text" color="primary" onClick={() => props.setShowReportPreview(false)} size="large">Back</Button>
      </Stack>
      <Box component="section" sx={{ border: "1px solid grey" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box component="section" sx={{ borderBottom: "1px solid grey" }}>
          <Typography variant="h2" component="h2" sx={{ textAlign: "center" }}>
            MOCK AUDIT
          </Typography>
          <Typography variant="h3" component="h3" sx={{ textAlign: "center" }}>
            OBSERVATIONS
          </Typography>
        </Box>
        <Box component="section" sx={{ borderBottom: "1px solid grey" }}>
          <Grid container>
            <Grid container item xs={8} md={8}>
              <BoxText>
                <Box>
                  <IObserved variant="h6" gutterBottom>
                    AUDITING FIRM ADDRESS AND PHONE NUMBER
                  </IObserved>
                </Box>
                <Box> Name: {assignment.auditing_firm_name} </Box>
                <Box> Address: {assignment.auditing_firm_address}</Box>
                <Box> Phone: {assignment.auditing_firm_phone}</Box>
                <Box> Fax: {assignment.auditing_firm_fax}</Box>
                <Box>Industry Information: www.fda.gov/oc/industry</Box>
              </BoxText>
            </Grid>
            <Grid container item xs={4} md={4} sx={{ borderLeft: "1px solid grey" }}>
              <Grid item xs={12} md={12}>
                <BoxText>
                  <IObserved variant="h6" gutterBottom>
                    DATE(S) OF INSPECTION
                  </IObserved>
                </BoxText>
              </Grid>
              <Grid item xs={12} md={12} sx={{ borderBottom: "1px solid grey" }}>
                <BoxText>
                  {getLocalDate(assignment.assignment_date)} - {getLocalDate(assignment.target_complete_date)}
                </BoxText>
              </Grid>
              <Grid item xs={12} md={12}>
                <BoxText>
                  <IObserved variant="h6" gutterBottom>
                    ID NUMBER
                  </IObserved>
                </BoxText>
              </Grid>
              <Grid item xs={12} md={12}>
                <BoxText>3001596594</BoxText>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box component="section" sx={{ borderBottom: "1px solid grey" }}>
          <Grid container>
            <Grid container item sx={{ padding: "0.5em" }}>
              <Grid item xs={12} md={12}>
                <IObserved variant="h6" gutterBottom>
                  NAME AND TITLE OF INDIVIDUAL TO WHOM REPORT IS ISSUED
                </IObserved>
              </Grid>
              <Grid item xs={12} md={12}>
                To: {assignment.audited_main_contact}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box component="section" sx={{ borderBottom: "1px solid grey" }}>
          <Grid container>
            <Grid container item xs={6} md={6} sx={{ padding: "0.5em" }}>
              <Grid item xs={12} md={12}>
                <IObserved variant="h6" gutterBottom>
                  FIRM TO BE AUDITED
                </IObserved>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box> Name: {assignment.audited_firm_name} </Box>
                <Box> Address: {assignment.audited_firm_address}</Box>
                <Box> Phone: {assignment.audited_firm_phone}</Box>
                <Box> Fax: {assignment.audited_firm_fax}</Box>
              </Grid>
            </Grid>
            <Grid container item xs={6} md={6} sx={{ borderLeft: "1px solid grey", padding: "0.5em" }}>
              <Grid item xs={12} md={12}>
                <IObserved variant="h6" gutterBottom>
                  TYPE ESTABLISHMENT INSPECTED
                </IObserved>
                <Box> {assignment.audited_establishment_type} </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box component="section" sx={{ borderBottom: "1px solid grey", padding: "0.5em" }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <IObserved variant="h6" gutterBottom>
                INSPECTION DETAIL
              </IObserved>
              <Box> TO BE FILLED. </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {/* OBSERVATION SECTION */}
          {reportType === REPORT_TYPE.OBSERVATION && !foundCitationHasNoSystemChoice && (
            <Grid container spacing={2}>
              <Grid item xs>
                <IObserved variant="h6" gutterBottom>
                  DURING AN AUDIT OF YOUR FIRM I/WE OBSERVED:
                </IObserved>
              </Grid>
            </Grid>
          )}
          {reportType === REPORT_TYPE.OBSERVATION &&
            codeTables &&
            codeTables.map((codeTable) => {
              const questionCitationsPerSystemChoice = questionCitations?.filter((p) => p.detail?.systemChoice === codeTable.code_table_uid);
              return (
                questionCitationsPerSystemChoice?.length > 0 &&
                questionCitationsPerSystemChoice.some((questionCitation) => questionCitation.detail.coverageChoice === COVERAGE_CHOICE_CITATION_GUID) && (
                  <div key={questionCitationsPerSystemChoice[0].code}>
                    <Grid container spacing={1}>
                      <Grid item xs>
                        <SystemChoice variant="h6" gutterBottom>
                          {codeTable.description}
                        </SystemChoice>
                      </Grid>
                    </Grid>

                    {questionCitationsPerSystemChoice
                      .filter((choice) => choice.detail.coverageChoice === COVERAGE_CHOICE_CITATION_GUID)
                      .map((questionCitation) => {
                        const { citationLanguage, citationSpecifically } = questionCitation.detail;
                        if (questionCitation) {
                          globalObservationIndex += 1;
                          return (
                            <ObservationIndex container key={questionCitation.code} spacing={1}>
                              <Grid item xs={12}>
                                <Divider />
                                OBSERVATION {globalObservationIndex}
                              </Grid>
                              <Grid item xs={12}>
                                {transformCitationLanguage(citationLanguage)}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  <pre style={{ fontFamily: "inherit" }}>Specifically, {citationSpecifically}</pre>
                                </Typography>
                              </Grid>
                            </ObservationIndex>
                          );
                        }
                        return <></>;
                      })}
                  </div>
                )
              );
            })}
          {reportType === REPORT_TYPE.OBSERVATION && foundCitationHasNoSystemChoice && questionCitations
                      .map((questionCitation) => {
                        const { citationLanguage, citationSpecifically } = questionCitation.detail;
                        if (questionCitation) {
                          globalObservationIndex += 1;
                          return (
                            <ObservationIndex container key={questionCitation.code} spacing={1}>
                              <Grid item xs={12}>
                                <Divider />
                                OBSERVATION {globalObservationIndex}
                              </Grid>
                              <Grid item xs={12}>
                                {transformCitationLanguage(citationLanguage)}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  <pre style={{ fontFamily: "inherit" }}>Specifically, {citationSpecifically}</pre>
                                </Typography>
                              </Grid>
                            </ObservationIndex>
                          );
                        }
                        return <></>;
          })}
          {/* DISCUSSION SECTION */}
          {reportType === REPORT_TYPE.DISCUSSION && (
            <Grid container spacing={2}>
              <Grid item xs>
                <IObserved variant="h6" gutterBottom>
                  DURING AN INSPECTION OF YOUR FIRM I/WE DISCUSSED:
                </IObserved>
              </Grid>
            </Grid>
          )}
          {reportType === REPORT_TYPE.DISCUSSION &&
            codeTables &&
            codeTables.map((codeTable) => {
              const questionCitationsPerSystemChoice = questionCitations?.filter((p) => p.detail?.systemChoice === codeTable.code_table_uid);
              return (
                questionCitationsPerSystemChoice?.length > 0 &&
                questionCitationsPerSystemChoice.some((questionCitation) => questionCitation.detail.coverageChoice === COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID) && (
                  <div key={questionCitationsPerSystemChoice[0].code}>
                    <Grid container spacing={1}>
                      <Grid item xs>
                        <SystemChoice variant="h6" gutterBottom>
                          {codeTable.description}
                        </SystemChoice>
                      </Grid>
                    </Grid>

                    {questionCitationsPerSystemChoice
                      .filter((choice) => choice.detail.coverageChoice === COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID)
                      .map((questionCitation) => {
                        const { citationLanguage, citationSpecifically } = questionCitation.detail;
                        if (questionCitation) {
                          globalDiscussionIndex += 1;
                          return (
                            <ObservationIndex container key={questionCitation.code} spacing={1}>
                              <Grid item xs={12}>
                                <Divider />
                                DISCUSSION {globalDiscussionIndex}
                              </Grid>
                              <Grid item xs={12}>
                                {transformCitationLanguage(citationLanguage)}
                              </Grid>
                              <Grid item xs={12}>
                                Specifically, {citationSpecifically}
                              </Grid>
                            </ObservationIndex>
                          );
                        }
                        return <></>;
                      })}
                  </div>
                )
              );
            })}
        </Box>
        <Box component="section" sx={{ borderTop: "1px solid grey" }}>
          <Grid container>
            <Grid container item>
              <Grid item xs={12} md={12} sx={{ borderLeft: "1px solid grey", padding: "0.5em" }}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <IObserved variant="h6" gutterBottom>
                      EMPLOYEE(S) SIGNATURE
                    </IObserved>
                  </Grid>
                  {allowedAssignTos?.map((item) => {
                    const foundAssignmentSignature = getAssignmentSignatureByAssignToEmail(assignmentsignatures, item.user_name);
                    return (
                      <Grid container xs={12} md={12} sx={{ borderBottom: "1px solid grey" }}>
                        <Grid item xs={8} md={8}>
                          {item.user_name} - {item.roleName}
                        </Grid>
                        {item.allowToSign ? <Grid item xs={1} md={1}>
                          <Grid container xs={12} md={12} >
                            <Grid item xs={4} md={4} >
                              <IconButton size="small" aria-label="sign" color='primary' disabled={item?.signature_url} onClick={() => handleOpenSignatureDialog({
                                  assignment_id: assignment.id,
                                  assign_to_email: item.user_name,
                                  assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid,
                                  signature_url: foundAssignmentSignature?.signature_url
                                })}>
                                <DrawIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={4} md={4} >
                              <IconButton size="small" aria-label="sign" color='primary' disabled={!item?.signature_url} onClick={() => handleDeleteAssignmentSignature({
                                  assignment_id: assignment.id,
                                  assign_to_email: item.user_name,
                                  assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid
                                })}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={4} md={4} >
                              <IconButton size="small" aria-label="sign" color='primary' onClick={() => handleAssignmentSignatureAudit({
                                  assignment_id: assignment.id,
                                  assign_to_email: item.user_name,
                                  assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid
                                })}>
                                <ManageHistoryIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid> 
                        : 
                        <Grid item xs={4} md={4}/>
                        }
                        <Grid item xs={12} md={12}>
                          <Grid item xs={12} md={12} > 
                            {item.signature_url && <img src={item.signature_url} alt="Not Found" width="200" height="40" />}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {/* <Grid item xs={3} md={3} sx={{ borderLeft: "1px solid grey", padding: "0.5em" }}>
                <Grid item xs={12} md={12}>
                  <IObserved variant="h6" gutterBottom>
                    DATE ISSUED
                  </IObserved>
                </Grid>
                <Grid item xs={12} md={12}>
                  02/05/2016
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ObservationSignatureDialog 
        open={openSignatureDialog} 
        setOpen={setOpenSignatureDialog}
        assignmentSignature={selectedAssignmentSignature}
        tenantId={tenantId} 
        handleAssignmentSignature={handleAssignmentSignature}/>
      <ObservationSignatureAuditDialog 
        open={openSignatureAuditDialog} 
        setOpen={setOpenSignatureAuditDialog}
        assignmentSignatureAuidts={selectedAssignmentSignatureAudits}
        setAssignmentSignatureAudits={setSelectedAssignmentSignatureAudits}/>
    </RootStyle>
  ) : (
    <>
      <CircularProgress />
      Observation Loading...{" "}
    </>
  );
});

export default ObservationReportGenerator;
